import React, { useEffect } from 'react'
import SimpleBanner from '../Common/SimpleBanner'
import Servicesbanar from '../../Assets/common/banar.jpeg'
import WeCareAbout from '../../Components/Services/WeCareAbout'
import Howwork from '../../Components/Services/Howwork'
import SliderImg from '../../Components/Services/SliderImg'
import BestService from '../../Components/Services/BestService'
import AOS from 'aos';
import 'aos/dist/aos.css'; 

const Services = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); 
  }, []);
  return (
    <React.Fragment>
      <SimpleBanner
        image={Servicesbanar}
        name="Our Services"
        path="Home / Services"
      />
      <WeCareAbout/>
      <BestService/>
      <Howwork/>
      <SliderImg/>
    </React.Fragment>
  )
}

export default Services