import React, { useEffect } from 'react'
import SimpleBanner from '../Common/SimpleBanner'
import Aboutbanar from '../../Assets/common/banar.jpeg'
import StoryAboutUs from '../../Components/AboutUs/StoryAboutUs.jsx'
import VisionMission from '../../Components/AboutUs/VisionMission.jsx'
import BestService from '../../Components/AboutUs/BestService.jsx'
import Discount  from '../../Components/AboutUs/Discount.jsx'
import AOS from 'aos';
import 'aos/dist/aos.css'; 
const About = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); 
  }, []);
  return (
    <React.Fragment>
      <SimpleBanner
        image={Aboutbanar}
        name="About Us"
        path="Home / About Us"
      />
      <StoryAboutUs/>
      <VisionMission/>
      <Discount/>
      <BestService/>
    </React.Fragment>
  )
}
export default About