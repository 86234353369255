import img1 from '../../Assets/Services/img1.png';
import img2 from '../../Assets/Services/img2.png';
import img3 from '../../Assets/Services/img3.png';
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const WeCareAbout = () => {
    useEffect(() => {
        AOS.init({ duration: 1000 }); 
      }, []);
    
    return (
        <React.Fragment>
            <h1 
                className='text-center text-4xl md:text-6xl mt-5 px-6 md:px-16'
                data-aos="fade-up" 
            >
                Filling Your Tummy <br/>
                is What We Care About.
            </h1>
            <p 
                className='text-center mt-5 text-sm md:text-base px-6 md:px-16'
                data-aos="fade-up" 
                data-aos-delay="200" 
            >
                Filling Your Tummy is What We Care About" reflects our commitment to delivering delicious, satisfying meals with care. We prioritize quality, timely delivery, and a great dining experience, ensuring every bite brings comfort and satisfaction.
            </p>

            <div className="flex flex-col md:flex-row gap-10 px-6 md:px-16 mt-10">
                <div 
                    className="bg-gray-50 p-6 rounded-lg shadow-lg text-center space-y-5 w-full md:w-1/3"
                    data-aos="zoom-in" 
                >
                    <hr className="border-[#2D8014] border-t-8 w-[50%] mx-auto rounded-md" />
                    <div className="mb-4">
                        <img src={img1} alt="Our Mission" className="mx-auto h-16 w-16" />
                    </div>
                    <hr className="border-[#2D8014] border-t-2 w-[70%] mx-auto" />
                    <h2 className="text-2xl md:text-3xl font-semibold mb-2 text-[#2D8014]">DELIVERY</h2>
                    <p className="text-gray-600 tracking-wide text-sm md:text-base">
                        Lorem ipsum dolor sit amet, consec tetur adipi scing elit. Phasellus viverra orci sed.
                    </p>
                </div>
                <div 
                    className="bg-gray-50 p-6 md:p-8 rounded-lg shadow-lg text-center space-y-5 w-full md:w-1/3"
                    data-aos="zoom-in" 
                    data-aos-delay="200" 
                >
                    <hr className="border-[#2D8014] border-t-8 w-[50%] mx-auto rounded-md" />
                    <div className="mb-4">
                        <img src={img2} alt="Our Vision" className="mx-auto h-16 w-16" />
                    </div>
                    <hr className="border-[#2D8014] border-t-2 w-[70%] mx-auto" />
                    <h2 className="text-2xl md:text-3xl font-semibold mb-2 text-[#2D8014]">ONLINE ORDER</h2>
                    <p className="text-gray-600 tracking-wide text-sm md:text-base">
                        Lorem ipsum dolor sit amet, consec tetur adipi scing elit. Phasellus viverra orci sed.
                    </p>
                </div>
                <div 
                    className="bg-gray-50 p-6 rounded-lg shadow-lg text-center space-y-5 w-full md:w-1/3"
                    data-aos="zoom-in"
                    data-aos-delay="400" 
                >
                    <hr className="border-[#2D8014] border-t-8 w-[50%] mx-auto rounded-md" />
                    <div className="mb-4">
                        <img src={img3} alt="Our History" className="mx-auto h-16 w-16" />
                    </div>
                    <hr className="border-[#2D8014] border-t-2 w-[70%] mx-auto" />
                    <h2 className="text-2xl md:text-3xl font-semibold mb-2 text-[#2D8014]">24/7 SERVICE</h2>
                    <p className="text-gray-600 tracking-wide text-sm md:text-base">
                        Lorem ipsum dolor sit amet, consec tetur adipi scing elit. Phasellus viverra orci sed.
                    </p>
                </div>
            </div>
        </React.Fragment>
    );
}

export default WeCareAbout;
