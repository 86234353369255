import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { TbMail } from "react-icons/tb";
import { MdOutlineLocalPhone } from "react-icons/md";

const TopNav = () => {
    return (
        <nav className="hidden md:block bg-[#191C1F] text-white px-6 md:px-16 py-2">
            <div className="container mx-auto flex items-center justify-between relative">
                {/* 1st Section - Phone and Email */}
                <div className="flex items-center space-x-4">
                    <div className="flex items-center space-x-1">
                        <MdOutlineLocalPhone size={22} color='#2D8014' />
                        <span className='text-sm tracking-wide'>
                            +0670-231347
                        </span>
                    </div>
                    <div className="flex items-center space-x-2">
                        <TbMail size={23} color='#2D8014' />
                        <span className='text-sm tracking-wide'>
                        Example@example.com
                        </span>
                    </div>
                </div>

               
              

                {/* 3rd Section - Social Media Icons */}
                <div className="flex space-x-4">
                    <div className="text-[#2D8014]">
                        <FaFacebookF />
                    </div>
                    <div className="text-[#2D8014]">
                        <FaTwitter />
                    </div>
                    <div className="text-[#2D8014]">
                        <FaInstagram />
                    </div>
                    <div className="text-[#2D8014]">
                        <FaLinkedinIn />
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default TopNav;
